<template>
  <ion-fab vertical="bottom" horizontal="end">
    <!-- <ion-fab-button
          @click="openModalNewKpiChat"
          class="ion-margin-end ion-margin-bottom"
        >
          <ion-icon :icon="cart"></ion-icon>
        </ion-fab-button> -->
    <ion-fab-button @click="dettaglioOrdine" class="icona-gotoCarrello">
      <ion-icon :icon="cart"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <master-layout
    smallTitle="il tuo"
    bigTitle="Catalogo"
    :showInfoPopHover="true"
    :noPadding="true"
    :infoPopHover="
      whappyClientConfig.name == 'Yoube'
        ? INFOPOPOVER_MAP[whappyClientConfig.name]
        : INFOPOPOVER_MAP['default']
    "
  >
    <div class="page ion-padding-top">
      <div v-if="has_customer && currentCustomer.id">
        <div
          v-if="currentCustomer.id"
          class="flex flex-column align-items-center pt-2 pb-2 ion-padding"
        >
          <button
            class="button-secondary py-2 px-3 mb-2"
            @click="ShoppingCartServiceResetCartResetCart"
          >
            Rimuovi cliente
          </button>

          <span class="font-bold">{{ currentCustomer.ragioneSociale }}</span
          ><span>{{
            currentCustomer.nome + " " + currentCustomer.cognome
          }}</span>
        </div>
        <ion-segment v-model="listToShow">
          <!-- ID 1 -->
          <ion-segment-button value="prodotti">
            <ion-label>Prodotti</ion-label>
          </ion-segment-button>
          <ion-segment-button value="bundles">
            <ion-label>Bundles</ion-label>
          </ion-segment-button>
          <!-- ID 2 -->
          <ion-segment-button value="pop">
            <ion-label>Materiale POP</ion-label>
          </ion-segment-button>
          <!-- ID 4 -->
          <ion-segment-button value="tester">
            <ion-label>Tester</ion-label>
          </ion-segment-button>
        </ion-segment>

        <ion-grid v-if="listToShow == 'prodotti'" class="ion-padding-top">
          <ion-row
            class="riga-prodotto"
            :class="index % 2 == 0 ? 'riga-prodotto-even' : null"
            v-for="(item, index) of items.filter((x) => x.idProductType == 1)"
            :key="item.code"
          >
            <ion-col @click="openModal(item)" size="4">
              <div class="miniatura-bundle">
                <img :src="item.pic" @error="handleImgError" />
                <div v-if="item.is_in_bundle" class="is-in-bundle"></div>
              </div>
            </ion-col>
            <ion-col
              @click="openModal(item)"
              size="3"
              class="flex flex-column align-items-start justify-content-center"
            >
              <span class="m-0 p-0 font-bold text-16">{{ item.name }}</span>
            </ion-col>
            <ion-col size="2">
              <span class="text-16">{{ item.format }}</span>
            </ion-col>
            <ion-col size="3" @click="openModal(item)">
              <span class="text-color-primary font-bold text-16">
                € {{ item.price.toFixed(2) }}</span
              >
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid v-if="listToShow == 'pop'" class="ion-padding-top">
          <ion-row
            class="riga-prodotto"
            :class="index % 2 == 0 ? 'riga-prodotto-even' : null"
            v-for="(item, index) of items.filter((x) => x.idProductType == 2)"
            :key="item.code"
          >
            <ion-col @click="openModal(item)" size="4">
              <div class="miniatura-bundle">
                <img :src="item.pic" @error="handleImgError" />
                <div v-if="item.is_in_bundle" class="is-in-bundle"></div>
              </div>
            </ion-col>
            <ion-col
              @click="openModal(item)"
              size="3"
              class="flex flex-column align-items-start justify-content-center"
            >
              <span class="m-0 p-0 font-bold text-16">{{ item.name }}</span>
            </ion-col>
            <ion-col size="2">
              <span class="text-16">{{ item.format }}</span>
            </ion-col>
            <ion-col size="3" @click="openModal(item)">
              <span class="text-color-primary font-bold text-16">
                € {{ item.price.toFixed(2) }}</span
              >
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid v-if="listToShow == 'tester'" class="ion-padding-top">
          <ion-row
            class="riga-prodotto"
            :class="index % 2 == 0 ? 'riga-prodotto-even' : null"
            v-for="(item, index) of items.filter(
              (x) => x.idProductType == 4 || x.idProductType == 5
            )"
            :key="item.code"
          >
            <ion-col @click="openModal(item)" size="4">
              <div class="miniatura-bundle">
                <img :src="item.pic" @error="handleImgError" />
                <div v-if="item.is_in_bundle" class="is-in-bundle"></div>
              </div>
            </ion-col>
            <ion-col
              @click="openModal(item)"
              size="3"
              class="flex flex-column align-items-start justify-content-center"
            >
              <span class="m-0 p-0 font-bold text-16">{{ item.name }}</span>
            </ion-col>
            <ion-col size="2">
              <span class="text-16">{{ item.format }}</span>
            </ion-col>
            <ion-col size="3" @click="openModal(item)">
              <span class="text-color-primary font-bold text-16">
                € {{ item.price.toFixed(2) }}</span
              >
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid v-if="listToShow == 'bundles'" class="ion-padding-top">
          <ion-row
            class="riga-prodotto"
            :class="index % 2 == 0 ? 'riga-prodotto-even' : null"
            v-for="(item, index) of bundles"
            :key="item.code"
          >
            <ion-col @click="openBundleModal(item)" size="3">
              <div class="miniatura-bundle">
                <img :src="item.pic" @error="handleImgError" />
              </div>
            </ion-col>
            <ion-col
              @click="openBundleModal(item)"
              size="3"
              class="flex flex-column align-items-start justify-content-center"
            >
              <span class="m-0 p-0 font-bold">{{ item.name }}</span>
            </ion-col>
            <ion-col size="3">
              <span>{{ item.format }}</span>
            </ion-col>
            <ion-col size="3" @click="openBundleModal(item)">
              <div
                class="flex flex-column align-items-start justify-content-center"
              >
                <span class="text-color-primary font-bold mb-2">
                  € {{ Number(item.price).toFixed(2) }}</span
                >
                <small
                  class="font-bold"
                  :style="item.isContovendita ? 'color: gray' : ''"
                  >{{ item.isContovendita ? "Contovendita" : "" }}</small
                >
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div v-else>
        <div
          class="text-align-center pt-4 mt-4 px-4 text-color-primary font-bold text-18"
        >
          Prima di procedere, per favore seleziona un cliente
        </div>

        <div class="flex flex-column px-4 py-1 seleziona-container">
          <div>
            <!-- <span class="text-14 font-bold mb-4">Seleziona il cliente</span> -->
            <div class="w-full my-4">
              <input
                :disabled="loadingGetData"
                class="w-full"
                v-model="filter"
                placeholder="Cerca cliente..."
              />
              <div
                v-if="filter.length > 0"
                class="flex w-full justify-content-end align-items-center"
              >
                <button class="button-primary p-d my-2" @click="getData">
                  {{ loadingGetData ? "Caricamento..." : "Cerca" }}
                </button>
              </div>
            </div>
            <div v-if="loadingGetData">
              Caricamento della lista clienti in corso...
            </div>
            <div v-else>
              <ion-row
                class="items ion-padding-horizontal ion-margin-bottom ion-margin-top ion-margin-top cursor-pointer"
                v-for="item of clientiOptions"
                :key="item.id"
                @click="selezionaCliente(item)"
              >
                <ion-col>
                  <ion-row
                    ><span class="font-bold">{{
                      item.ragioneSociale
                    }}</span></ion-row
                  >
                  <ion-row> {{ item.nome }} {{ item.cognome }} </ion-row>
                  <ion-row>
                    <ion-col>
                      <gradient-progress-bar
                        class="height-5 gradient"
                        :value="item.hotness"
                      ></gradient-progress-bar>
                    </ion-col>
                  </ion-row>
                </ion-col>
                <ion-col class="ion-text-end ion-padding-top" size="2">
                </ion-col>
              </ion-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  modalController,
  IonSegment,
  IonSegmentButton,

} from "@ionic/vue";
import DettaglioOrdine from "../Catalogo/DettaglioOrdine.vue";
import { cart } from "ionicons/icons";
import SingleItem from "./SingleItem.vue";
import SingleBundle from "./SingleBundle.vue";
import ShoppingCartService from "../../common/mixins/ShoppingCart.vue";
import ApiService from "../../common/service.api";

import GradientProgressBar from "@/components/GradientProgressBar.vue";
export default {
  name: "catalogo",
  components: {
    IonFab,
    IonFabButton,
    IonIcon,
    IonLabel,
    IonCol,
    IonGrid,
    IonRow,
    SingleItem,
    IonSegment,
    IonSegmentButton,
    GradientProgressBar
  },
  mixins: [ShoppingCartService],
  created() {},

  mounted() {
    if (
      this.$store.getters["shoppingCart/getCurrentCart"].customer.name !== ""
    ) {
      this.currentCustomer =
        this.$store.getters["shoppingCart/getCurrentCart"].customer;
    }

    this.has_customer =
      this.$store.getters["shoppingCart/getCurrentCart"].customer.name !== "";

    this.getProducts();
    this.getBoundles();
  },
  data() {
    return {
      loadingGetData: false,
      cart,
      has_customer: false,
      listToShow: "prodotti",
      loadingItems: false,
      loadingBoundles: false,
      currentCustomer: {},
      items: [],
      itemsPop: [],
      itemsTester: [],
      bundles: [],
      clientiOptions: [],
      objectToPost: {
        idTipoAnagrafica: 0,
        idUtente: 0,
        idPadre: 0,
        nome: "",
        cognome: "",
        ragioneSociale: "",
        partitaIva: "",
        ricerca: "",
        codiceFiscale: "",
        email: "",
        telefono: "",
        cellulare: "",
        citta: "",
        provincia: "",
        regione: "",
        nazione: "",
        note: "",
        anagraficaYoube: null,
      },
      filter: "",
      INFOPOPOVER_MAP: {
        Yoube:
          "In questa sezione puoi effettuare direttamente gli ordini, sia per singoli prodotti, sia per impianti. Ti aspettano sconti e promo speciali. Clicca sull’immagine del carrello per procedere e inviare l’ordine.",
        default:
          "In questa sezione puoi effettuare direttamente gli ordini, sia per singoli prodotti, sia per impianti. Ti aspettano sconti e promo speciali. Clicca sull’immagine del carrello per procedere e inviare l’ordine.",
      },
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    getData() {
      if (this.filter === "") return;

      this.loadingGetData = true;
      this.objectToPost.ricerca = this.filter;
      ApiService.post("Ecommerce/GetAnagsAll", this.objectToPost)
        .then((res) => {
          this.clientiOptions = res.data;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loadingGetData = false;
        });
    },
    selezionaCliente(customer) {
      this.has_customer = true;
      this.currentCustomer = customer;
      this.setCustomer(customer);
    },
    getProducts() {
      this.loadingItems = true;
      ApiService.get("Ecommerce/GetProducts")
        .then((res) => {
          res.data.forEach((prod) => {
            if (prod.attivo) {
              this.items.push({
                id: prod.id,
                code: prod.codiceEan,
                codiceInterno: prod.codiceInterno,
                colloOriginale: prod.colloOriginale,
                name: prod.nomeProdotto,
                pic: prod.immagineProdotto,
                description: prod.descrizione,
                format: prod.quantitaConfezione,
                price: prod.prezzoPubblico,
                idProductType: prod.idProductType,
                iva: prod.iva,
                quantitaConfezione: prod.quantitaConfezione,
                number: 0,
                is_in_bundle: false,
              });
            }
          });
        })
        .finally(() => {
          this.loadingItems = false;
        });
    },

    getBoundles() {
      this.loadingBoundles = true;
      ApiService.get("Ecommerce/GetBundlesV2")
        .then((res) => {
          res.data.forEach((boundle) => {
            this.bundles.push({
              id: boundle.id,
              name: boundle.nomeBoundle,
              pic: boundle.immagineBoundle,
              format: boundle.prodottiBundle.length || "0",
              price: boundle.prezzo,
              items: boundle.prodottiBundle,
              isContovendita: boundle.isContovendita,
            });
          });
        })
        .finally(() => {
          this.loadingBoundles = false;
        });
    },
    addItemToCart(item) {
      this.addItem(item);
    },
    ShoppingCartServiceResetCartResetCart() {
      this.currentCustomer = null;
      this.has_customer = false;
    },
    async openModal(item) {
      const modal = await modalController.create({
        component: SingleItem,
        cssClass: "modal-single-item-or-bundle",
        componentProps: {
          item: item,
        },
      });
      return modal.present();
    },
    async openBundleModal(item) {
      const modal = await modalController.create({
        component: SingleBundle,
        cssClass: "modal-single-item-or-bundle",
        componentProps: {
          item: item,
        },
      });
      return modal.present();
    },
    dettaglioOrdine() {
      this.$router.push("/shopping-cart");
    },
  },
};
</script>

<style scoped lang="scss">
ion-grid {
  font-size: 0.8rem;
  /* font-weight: bolder; */
  color: grey;
}

img {
  border-radius: 4px;
}

.titolo {
  text-align: center;
  font-size: 2rem;
  font-weight: bolder;
}

.table-header ion-col {
  font-weight: bold;
}

.riga-prodotto ion-col {
  display: grid;
  place-items: center;
}

.riga-prodotto-even {
  background: var(--client-ground1-color) !important;
}

.miniatura-bundle {
  position: relative;
  width: 90%;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.miniatura-bundle:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.icona-gotoCarrello {
  margin-top: 3.14rem;
  margin-right: 1rem;
}

.page {
  background: var(--client-ground2-color);
}

.is-in-bundle {
  position: absolute;
  top: -3px;
  right: -3px;

  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: var(--ion-color-primary);
}
</style>
